@import url("https://use.typekit.net/yku3pbz.css");

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

body {
  margin: 0;
  font-family: "neue-haas-unica", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav {
  position: fixed;
  color: white;
  z-index: 20;
  padding: 30px;
  font-weight: bold;
}

.entryWrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 25000000;
  width: 550px;
  overflow-y: scroll;
  height: 100vh;
  padding: 0px 50px 0px 0px;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0)
  );
}

.etymology {
  font-family: "freight-text-pro", serif;
  font-size: 105%;
  padding-left: 20px;
}

.entry {
  border-bottom: solid #fff 1px;
  color: white;
  padding: 30px 40px 40px;
  font-size: 90%;
  overflow-y: scroll;
  position: relative;
  flex-grow: 1;
}

.entry a {
  color: white;
  text-decoration: none;
}

.entry .footnotes hr {
  margin-block-start: 2em;
  margin-block-end: 1.5em;
}

.entry p {
  margin: 0px 0px 15px;
}

.dateRange {
  position: fixed;
  height: 100vh;
  right: 0;
  padding: 20px 30px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

.entryWrapper button {
  transition: background-color 0.3s, color 0.3s;
  background: none;
  color: white;
  border: none;
  width: 100%;
  margin: 0;
  padding: 25px 0px;
  cursor: pointer;
}

.info {
  position: fixed;
  z-index: 1;
  color: white;
  bottom: 0;
  padding: 30px;
}

.info button {
  margin: 0;
  transition: color 0.3s;
  border: none;
  background: none;
  display: block;
  padding: 0;
  color: white;
  font-size: 0.85rem;
}

.info a {
  color: white;
  text-decoration: none;
  font-size: 0.85rem;
}

.info button:hover {
  color: red;
  cursor: pointer;
}

.info a:hover {
  color: rgba(11, 142, 59, 1);
}

.infoPanel {
  color: white;
  padding: 30px;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
  z-index: 25000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infoPanel p {
  width: 600px;
  margin: 0px 0px 20px;
}

.infoPanel a {
  color: white;
}

.infoPanel a:hover {
  color: green;
}

.entryWrapper button:hover {
  background-color: white;
  color: black;
}

.definitions {
  list-style: decimal;
}

.definitions ul {
}
.definitions li {
  padding: 0px 0px 10px 10px;
}

.markerText {
  transition: background-color 0.4s;
  color: white;
  font-size: 1px;
  cursor: pointer;
  border-radius: 0.25px;
  border: none;
  position: absolute;
  font-weight: 400;
  padding: 1px 1px;
  margin-top: -2px;
  text-align: center;
  white-space: nowrap;
}

.markerText:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.info-icon {
  width: 0.85rem;
  height: 0.85rem;
  margin-right: 8px;
}

@media (max-width: 550px) {
  .dateRange {
    display: none;
  }
  .entryWrapper {
    width: 100vw;
    min-width: 400px;
  }

  .infoPanel {
    padding: 40px;
  }

  .infoPanel p {
    width: 100%;
    min-width: 400px;
  }
}
